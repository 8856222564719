:root {

    --muli: 'Muli', sans-serif;
    --roboto: 'Roboto', sans-serif;
    --bg-color: #1a0e3a;
    --color-secondary: #a08ed1;
    --color-alt-primary: #89878f2c;
    --color-primary: #d1c7f1;
    --trim-primary-color: #e83283;
    --trim-color: #f17cb1e7;
    --trim-alt-primary: #f5dce6;
    --color-success: #41da7a;
    --color-info: #39cbfb;
    --twitter: #1da1f2;
    --linkedin: #0077b5;
    --github: white;
    --shadow-text: -1px 0 var(--trim-primary-color), 
        0 1px var(--trim-primary-color), 
        1px 0 var(--trim-primary-color), 
        0 -1px var(--trim-primary-color);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html,
body {
    background: var(--bg-color);
    height: 100vh;
    width: 100%;
    font-family: var(--muli);
    font-size: 1em;
    color: var(--color-primary);
    line-height: 1.5;
    margin: 0;
    padding: 0;
    /* prevent shift right on modal load */
    padding-right: 0px !important; 
    position: relative!important;
}
html {
    scrollbar-width: thin;
    scrollbar-color: var(--color-secondary) var(--color-primary);
}
body::-webkit-scrollbar {
    width: .5em;
}
body::-webkit-scrollbar-track {
    border-radius: 10px;
}
body::-webkit-scrollbar-thumb {
    border-radius: 6px;
    border: 1px solid var(--trim-primary-color);
}
body::-webkit-scrollbar-thumb:hover {
    background: var(--trim-primary-color);
}

.row-content {
    padding: 50px 0;
    min-height: 200px;
}
.quickToast {
    border: none;
    border: 1px solid var(--color-primary); 
    background: var(--bg-color);
    backdrop-filter: none;
    color: var(--color-success);
}

/* NAVIGATION */
.navbar-toggler {
    position: relative;
}
.navbar-toggler:hover,
.navbar-toggler:focus,
.navbar-toggler:active {
    outline: none;
    border-radius: 0em;
    box-shadow: none;
}
nav {
    background: none
}
.myNav {
    background: var(--bg-color);
}
.nav-link {
    font-size: 1.1em;
    font-weight: 500;
    margin: 0 8px;
    color: var(--color-primary);
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.ph-link {
    color: var(--color-primary);
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.nav-link:hover,
.nav-link:focus,
.nav-link:active,
.ph-link:hover,
.ph-link:focus,
.ph-link:active {
    color: var(--trim-primary-color);
}

/* LANDING PAGE */
.pre-text {
    font-size: 1.1em;
    margin-bottom: 1em;
    margin-left: .25em;
}
.intro-text {
    font-size: 2.5em;
    margin-bottom: 1em;
}
.sub-text {
    font-size: 2.5em;
    color: var(--color-secondary);
    font-weight: 800;
}
.text {
    font-size: 1.1em;
    color: var(--color-secondary);
}

/* ABOUT */
.info-header {
    margin-bottom: 1em;
}
.about-intro {
    margin-bottom: 1em;
    font-weight: 500;
}
.resBtn {
    color: var(--trim-primary-color);
    box-shadow:  10px 10px 20px #130a2a,
        -10px -10px 20px #21124a;
}
.resBtn:hover,
.resBtn:focus,
.resBtn:active {
    color: white;
    background: linear-gradient(145deg, #f8368c, #d12d76);
    outline: none;
    opacity: 75%;
}
.touch-link {
    color: var(--color-primary);
}

/* SKILLS */

.skill-one {
    width: 70px;
    height: 70px;
}
.skill-two {
    width: 80px;
    height: 70px;
}

/* PROJECTS */
/* Cards */
.p-card {
    background: var(--color-secondary);
    border: 2px solid var(--color-primary);
}
.cd-img {
    border-radius: 8px;
    transition: .5s ease;
    backface-visibility: hidden;
}
.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
.p-card:hover {
    background: var(--color-alt-primary);
    background: rgba(255, 255, 255, 0.04);
}
.p-card:hover .cd-img{
    opacity: .25;
    background: rgba(255, 255, 255, 0.04);
}
.p-card:hover .middle {
    opacity: 50%;
}

/* Modals */
.modal-content {
    background: var(--bg-color);
    border: 1px solid var(--trim-primary-color);
    outline: none;
}
.modalTitle {
    font-weight: 500;
}
.modal-body {
    border: none;
    outline: none;
    border-top: 1px solid var(--trim-primary-color);
    border-bottom: 1px solid var(--trim-primary-color);
}
video {
    border-radius: 10px;
    border: 1px solid #d12d76;
    box-shadow: 10px 10px 25px #130a2a,
        -10px -10px 25px #21124a;
}
.modal-description {
    color: var(--color-secondary);
}
.btnClose {
    color: var(color-secondary);
    font-weight:500;
    background: none;
    border: none;
}
.btnClose:hover,
.btnClose:focus,
.btnClose:active {
    opacity: 0.5;
    background: none;
}
.modal-link {
    text-shadow: var(--shadow-text);
    color: var(--color-secondary);
}
.modal-link:hover,
.modal-link:focus,
.modal-link:active {
    color: var(--trim-color);
}

/* CONTACT */
.form-holder {
    border-radius: 10px;
    border: 1px solid var(--trim-primary-color);
    background: none;
    box-shadow: 9px 9px 16px #160c32,
    -9px -9px 16px #1e1042;
}
.form {
    background: none;
}
.form-control::-webkit-input-placeholder {
    font-size: 1.05em;
}

input:not(:placeholder-shown) {
    background:  none!important;
}
.contact-input {
    border: 1px solid var(--trim-primary-color);
    outline: none;
    box-shadow: 10px 10px 25px #130a2a,
        -10px -10px 25px #21124a;
}
.contact-input:focus {
    border-radius: 5px;
    outline: 1px solid var(--trim-primary-color);
}
.form-control:valid {
    color: var(--trim-alt-primary)!important;
}
.contact-text,
.contact-text-link {
    font-size: 1.2em;
    color: var(--color-secondary);
}
.contact-text-link:hover {
    color: var(--trim-primary-color);
}
.sendBtn {
    width: 98.5px;
    height: 50px;
    color: var(--color-primary);
    padding: 12px 24px;
    border-radius: 8px;
    border: 1px solid var(--trim-primary-color);
    background: var(--bg-color);
    box-shadow:  10px 10px 20px #130a2a,
        -10px -10px 20px #21124a;
}

.sendBtn:hover,
.sendBtn:focus,
.sendBtn:active {
    color: white;
    background: linear-gradient(145deg, #f8368c, #d12d76);
    border: 1px solid var(--trim-primary-color);
    outline: none;
}

/* SOCIALS */
.list-group{
    background: transparent;
    outline: transparent;
    border: none;
}
.social-ul li a {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: 0 .5rem;
    background: none;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.04);
    box-shadow: -7px 7px 8px #160c31,
        7px -7px 8px #1e1043;
}

.list-group-item {
    background: transparent;
}
.social:hover {
    background: var(--trim-primary-color);
    box-shadow: 7px 7px 14px #130a2a,
        -7px -7px 14px #21124a;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
.linkedin {
    color: var(--linkedin);
}
.github{
    color: var(--github);
}
.twitter{  
    color: var(--twitter);
}

/* CV */
.react-pdf__Page__canvas {
    background: var(--bg-color);
}

.resume-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
}
.PDFPageOne {
    margin-bottom: 25px;
}
.PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
}
.react-pdf__Page__annotations,
.annotationsLayer {
    display: none;
}
.react-pdf__Page__canvas {
    width: 100%;
    height: 100%;
}

.footer-text {
    color: var(--trim-primary-color);
    font-weight: 500;
}

/* BREAKPOINTS */
@media screen and (min-width: 576px) {
    
    .intro-text, 
    .sub-text {
        font-size: 2.9em;
        
    }
    .text {
        font-size: 1.25em;
    }
}
@media screen and (min-width: 768px) {
    .intro-text, 
    .sub-text {
        font-size: 3.6em;
        
    }
    .social-ul li a {
        box-shadow: 9px 9px 18px #140b2d,
            9px -9px 18px #201147;
    }
}
@media screen and (min-width: 992px) {
    .intro-text, 
    .sub-text {
        font-size: 4.3em;
        
    }
}
@media screen and (min-width: 1200px) {
    .intro-text, 
    .sub-text {
        font-size: 5em;
        
    }
}